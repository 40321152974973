import React, { useState, useEffect } from 'react';
import { Plus, Search, X, User } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';

const DirectMessageList = ({ directMessages, activeChannel, onDirectMessageSelect, isDirectMessage, getColorForInitial }) => {
  const { userProfile } = useAuth();
  const [showNewMessagePanel, setShowNewMessagePanel] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [businessUsers, setbusinessUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);

  // Load users from the same business when the new message panel opens for the first time
  useEffect(() => {
    if (showNewMessagePanel && !hasAttemptedLoad) {
      loadbusinessUsers();
      setHasAttemptedLoad(true);
    }
  }, [showNewMessagePanel, hasAttemptedLoad]);

  const loadbusinessUsers = async () => {
    if (!userProfile?.businessId) {
      setError("No business found in your profile");
      return;
    }
    setIsLoading(true);
    setError('');
    try {
      // Query the "userProfiles" collection to get users from the same business
      const userProfilesRef = collection(db, "userProfiles");
      const q = query(
        userProfilesRef,
        where("businessId", "==", userProfile.businessId),
        limit(100)
      );
      const querySnapshot = await getDocs(q);
      const usersList = [];
      querySnapshot.forEach((doc) => {
        // Exclude the current user from the results
        if (doc.id !== userProfile.id) {
          const userData = doc.data();
          usersList.push({
            id: doc.id,
            name: `${userData.title || ''} ${userData.firstName || ''} ${userData.lastName || ''}`.trim() || "Unknown User",
            avatar: `${userData.firstName?.charAt(0) || ''}${userData.lastName?.charAt(0) || '?'}`,
            role: userData.userRole || 'User',
            businessId: userData.businessId || 'No business'
          });
        }
      });
      if (usersList.length === 0) {
        setError("No other users found in your business");
      }
      setbusinessUsers(usersList);
      setFilteredUsers(usersList);
    } catch (err) {
      console.error("Error loading business users:", err);
      setError(`Failed to load users: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Filter users based on the search term
  useEffect(() => {
    if (!showNewMessagePanel) return;
    if (!searchTerm.trim()) {
      setFilteredUsers(businessUsers);
      return;
    }
    const filtered = businessUsers.filter(user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchTerm, businessUsers, showNewMessagePanel]);

  // Start a new direct message conversation
  const handleStartNewConversation = (user) => {
    onDirectMessageSelect(user.id);
    setShowNewMessagePanel(false);
    setSearchTerm('');
  };

  const toggleNewMessagePanel = () => {
    setShowNewMessagePanel(!showNewMessagePanel);
    if (!showNewMessagePanel) {
      setSearchTerm('');
    }
  };

  return (
    <div className="px-4 py-3 flex-none">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-xs font-semibold text-[#f7f7f7] uppercase tracking-wider">Direct Messages</h3>
        <button 
          className="text-[#f7f7f7] hover:text-white"
          onClick={toggleNewMessagePanel}
        >
          <Plus size={16} />
        </button>
      </div>
      
      {/* New Message Panel */}
      {showNewMessagePanel && (
        <div className="mb-3 bg-[#ffffff22] p-2 rounded-md">
          <div className="flex items-center justify-between mb-2">
            <p className="text-xs text-[#f7f7f7] font-medium">New Message</p>
            <button
              className="text-[#f7f7f7] hover:text-white"
              onClick={() => setShowNewMessagePanel(false)}
            >
              <X size={14} />
            </button>
          </div>
          
          <div className="mb-2">
            <div className="relative">
              <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={14} />
              <input
                type="text"
                placeholder="Search users..."
                className="w-full pl-8 pr-2 py-1 text-sm border rounded focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white text-gray-800"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              />
            </div>
          </div>
          
          {error && (
            <p className="text-xs text-red-300 mb-1">{error}</p>
          )}
          
          <div className="max-h-60 overflow-y-auto">
            {isLoading ? (
              <div className="text-center py-2">
                <div className="animate-spin h-4 w-4 border-2 border-blue-500 rounded-full border-t-transparent mx-auto"></div>
                <p className="text-xs text-[#f7f7f7] mt-1">Loading users...</p>
              </div>
            ) : filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <div 
                  key={user.id}
                  className="flex items-center p-2 hover:bg-[#ffffff33] rounded cursor-pointer"
                  onClick={() => handleStartNewConversation(user)}
                >
                  <div className={`w-6 h-6 rounded-full bg-white flex items-center justify-center text-[#1d67cd] text-xs font-medium mr-2`}>
                    {user.avatar}
                  </div>
                  <div>
                    <p className="text-sm font-medium text-[#f7f7f7] truncate max-w-[180px]">{user.name}</p>
                    <p className="text-xs text-gray-300">{user.role}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-xs text-[#f7f7f7] text-center py-2">
                {searchTerm ? 'No users match your search' : 'No users found in your business'}
              </p>
            )}
          </div>
        </div>
      )}
      
      {/* Existing Direct Messages List */}
      <div className="space-y-1">
        {directMessages.map((dm) => (
          <button
            key={dm.id}
            className={`flex items-center justify-between w-full px-2 py-1.5 rounded-md text-sm ${
              activeChannel === dm.id && isDirectMessage
                ? 'bg-[#ffffff33] text-white'
                : 'hover:bg-[#ffffff22] text-[#f7f7f7]'
            }`}
            onClick={() => onDirectMessageSelect(dm.id)}
          >
            <div className="flex items-center">
              <div className="relative">
                <div className={`w-6 h-6 rounded-full bg-${dm.color}-500 flex items-center justify-center text-white font-medium mr-2`}>
                  {dm.avatar}
                </div>
                <span className={`absolute bottom-0 right-0 w-2 h-2 rounded-full border border-[#1d67cd] ${
                  dm.status === 'online' ? 'bg-green-500' :
                  dm.status === 'away' ? 'bg-yellow-500' :
                  'bg-gray-300'
                }`}></span>
              </div>
              <span className="truncate max-w-[140px]">{dm.name}</span>
            </div>
            {dm.unread > 0 && (
              <span className="bg-white text-[#1d67cd] text-xs font-medium px-2 py-0.5 rounded-full">
                {dm.unread}
              </span>
            )}
            
          </button>
        ))}
        
        {directMessages.length === 0 && !showNewMessagePanel && (
          <div className="text-center py-4">
            <User className="mx-auto h-6 w-6 text-[#f7f7f7] opacity-50" />
            <p className="text-sm text-[#f7f7f7] mt-2">No direct messages yet</p>
            <p className="text-xs text-[#f7f7f7] opacity-70 mt-1">Click the + icon to start a conversation</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectMessageList;