import React, { useEffect, useRef } from 'react';
import { X, Download, ArrowLeft, ArrowRight } from 'lucide-react';

const MediaModal = ({ media, onClose, onNext, onPrevious, hasNext, hasPrevious }) => {
  const modalRef = useRef(null);

  // Listen for keyboard events to close or navigate the modal
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      } else if (e.key === 'ArrowRight' && hasNext) {
        onNext();
      } else if (e.key === 'ArrowLeft' && hasPrevious) {
        onPrevious();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose, onNext, onPrevious, hasNext, hasPrevious]);

  // Close modal when clicking outside the content area
  const handleModalClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center p-4"
      onClick={handleModalClick}
    >
      <div className="absolute top-4 right-4 flex space-x-2">
        <a 
          href={media.url} 
          download={media.name}
          target="_blank" 
          rel="noreferrer" 
          className="bg-gray-800 bg-opacity-50 hover:bg-opacity-75 text-white p-2 rounded-full"
          onClick={(e) => e.stopPropagation()}
        >
          <Download size={24} />
        </a>
        <button 
          onClick={onClose}
          className="bg-gray-800 bg-opacity-50 hover:bg-opacity-75 text-white p-2 rounded-full"
        >
          <X size={24} />
        </button>
      </div>

      {/* Navigation buttons */}
      {hasPrevious && (
        <button 
          onClick={(e) => { e.stopPropagation(); onPrevious(); }}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 hover:bg-opacity-75 text-white p-2 rounded-full"
        >
          <ArrowLeft size={24} />
        </button>
      )}
      
      {hasNext && (
        <button 
          onClick={(e) => { e.stopPropagation(); onNext(); }}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 hover:bg-opacity-75 text-white p-2 rounded-full"
        >
          <ArrowRight size={24} />
        </button>
      )}

      {/* Media content */}
      <div 
        ref={modalRef}
        className="max-w-screen-lg max-h-screen-lg"
        onClick={(e) => e.stopPropagation()}
      >
        {media.type.startsWith('image/') ? (
          <img 
            src={media.url} 
            alt={media.name || 'Image attachment'}
            className="max-h-[85vh] max-w-full object-contain"
          />
        ) : media.type.startsWith('video/') ? (
          <video 
            controls
            autoPlay
            className="max-h-[85vh] max-w-full"
          >
            <source src={media.url} type={media.type} />
            Your browser does not support the video tag.
          </video>
        ) : null}
        <div className="mt-2 bg-black bg-opacity-50 p-2 text-white text-sm rounded">
          {media.name}
        </div>
      </div>
    </div>
  );
};

export default MediaModal;
