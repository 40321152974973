import React, { useState, useEffect } from 'react';
import { X, Search, Users, UserPlus, UserMinus, Check, Plus, Shield } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  getDoc, 
  updateDoc, 
  arrayUnion, 
  arrayRemove 
} from 'firebase/firestore';

const ChannelMembers = ({ channelId, isOpen, onClose }) => {
  const { currentUser, userProfile } = useAuth();
  const [channel, setChannel] = useState(null);
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [activeTab, setActiveTab] = useState('current'); // 'current' or 'add'
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Expose a dummy method to open this modal externally
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.channelMembersModal = {
        openModal: (id) => {
          console.log('Request to open modal for channel:', id);
          // In a real implementation, you would update state via context or a state management solution
        }
      };
    }
  }, []);

  // Fetch channel data and its members when modal opens
  useEffect(() => {
    if (!channelId || !isOpen) return;
    
    const fetchChannelAndMembers = async () => {
      setIsLoading(true);
      setError('');
      
      try {
        const channelDoc = await getDoc(doc(db, "channels", channelId));
        if (!channelDoc.exists()) {
          setError("Channel not found");
          setIsLoading(false);
          return;
        }
        
        const channelData = { id: channelDoc.id, ...channelDoc.data() };
        setChannel(channelData);
        
        // Fetch member details from "users" collection
        const membersList = [];
        const memberIds = channelData.members || [];
        if (memberIds.length > 0) {
          for (const memberId of memberIds) {
            try {
              const userDoc = await getDoc(doc(db, "users", memberId));
              if (userDoc.exists()) {
                membersList.push({
                  id: userDoc.id,
                  ...userDoc.data(),
                  isCurrentUser: userDoc.id === currentUser?.uid,
                  isAdmin: memberId === channelData.admin || memberId === channelData.createdBy
                });
              }
            } catch (err) {
              console.error("Error fetching member:", err);
            }
          }
        }
        setMembers(membersList);
      } catch (err) {
        console.error("Error fetching channel:", err);
        setError("Failed to load channel information");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchChannelAndMembers();
  }, [channelId, currentUser, isOpen]);

  // Check if current user is admin for the channel
  const isUserAdmin = () => {
    return channel && (channel.admin === currentUser?.uid || channel.createdBy === currentUser?.uid);
  };

  // Search for users to add to the channel
  const handleSearch = async () => {
    if (!searchTerm || searchTerm.length < 2) return;
    
    setIsLoading(true);
    setError('');
    
    try {
      const usersRef = collection(db, "users");
      const q = query(
        usersRef,
        where("institutionName", "==", userProfile?.institutionName || ""),
        where("firstName", ">=", searchTerm),
        where("firstName", "<=", searchTerm + '\uf8ff')
      );
      const querySnapshot = await getDocs(q);
      const results = [];
      const memberIds = members.map(member => member.id);
      
      querySnapshot.forEach((doc) => {
        if (!memberIds.includes(doc.id)) {
          results.push({
            id: doc.id,
            ...doc.data(),
            isSelected: selectedUsers.includes(doc.id)
          });
        }
      });
      
      // Fallback search by last name if no results found
      if (results.length === 0) {
        const lastNameQuery = query(
          usersRef,
          where("institutionName", "==", userProfile?.institutionName || ""),
          where("lastName", ">=", searchTerm),
          where("lastName", "<=", searchTerm + '\uf8ff')
        );
        const lastNameSnapshot = await getDocs(lastNameQuery);
        lastNameSnapshot.forEach((doc) => {
          if (!memberIds.includes(doc.id)) {
            results.push({
              id: doc.id,
              ...doc.data(),
              isSelected: selectedUsers.includes(doc.id)
            });
          }
        });
      }
      setSearchResults(results);
    } catch (err) {
      console.error("Error searching users:", err);
      setError("Failed to search for users");
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle selection of a user from search results
  const toggleUserSelection = (userId) => {
    setSelectedUsers(prev => 
      prev.includes(userId) ? prev.filter(id => id !== userId) : [...prev, userId]
    );
    setSearchResults(prev => 
      prev.map(user => user.id === userId ? { ...user, isSelected: !user.isSelected } : user)
    );
  };

  // Add selected users to the channel
  const addUsersToChannel = async () => {
    if (!selectedUsers.length) return;
    
    setIsLoading(true);
    setError('');
    
    try {
      const channelRef = doc(db, "channels", channelId);
      for (const userId of selectedUsers) {
        await updateDoc(channelRef, {
          members: arrayUnion(userId),
          membersCount: (channel?.membersCount || 0) + 1
        });
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          setMembers(prev => [
            ...prev, 
            {
              id: userId,
              ...userDoc.data(),
              isCurrentUser: userId === currentUser?.uid,
              isAdmin: false
            }
          ]);
        }
      }
      setSuccess(`${selectedUsers.length} user(s) added to the channel`);
      setSelectedUsers([]);
      setSearchResults([]);
      setSearchTerm('');
      setActiveTab('current');
    } catch (err) {
      console.error("Error adding users to channel:", err);
      setError("Failed to add users to the channel");
    } finally {
      setIsLoading(false);
    }
  };

  // Remove a member from the channel
  const removeMember = async (userId) => {
    if (userId !== currentUser?.uid && !isUserAdmin()) {
      setError("Only channel admins can remove other members");
      return;
    }
    
    setIsLoading(true);
    setError('');
    
    try {
      const channelRef = doc(db, "channels", channelId);
      await updateDoc(channelRef, {
        members: arrayRemove(userId),
        membersCount: Math.max(0, (channel?.membersCount || 1) - 1)
      });
      setMembers(prev => prev.filter(member => member.id !== userId));
      setSuccess(userId === currentUser?.uid 
        ? "You have left the channel" 
        : "Member removed from channel");
      if (userId === currentUser?.uid) {
        setTimeout(() => onClose(), 1500);
      }
    } catch (err) {
      console.error("Error removing member:", err);
      setError("Failed to remove member from the channel");
    } finally {
      setIsLoading(false);
    }
  };

  // Join channel (self-add)
  const joinChannel = async () => {
    if (channel.type === 'private' && !isUserAdmin()) {
      setError("This is a private channel. You need an invitation to join.");
      return;
    }
    
    setIsLoading(true);
    setError('');
    
    try {
      const channelRef = doc(db, "channels", channelId);
      await updateDoc(channelRef, {
        members: arrayUnion(currentUser.uid),
        membersCount: (channel?.membersCount || 0) + 1
      });
      if (!members.some(m => m.id === currentUser.uid)) {
        setMembers(prev => [
          ...prev,
          {
            id: currentUser.uid,
            ...userProfile,
            isCurrentUser: true,
            isAdmin: false
          }
        ]);
      }
      setSuccess("You have joined the channel");
      setTimeout(() => onClose(), 1500);
    } catch (err) {
      console.error("Error joining channel:", err);
      setError("Failed to join the channel");
    } finally {
      setIsLoading(false);
    }
  };

  // Leave channel (self-remove)
  const leaveChannel = async () => {
    if (isUserAdmin() && members.length > 1) {
      setError("As the admin, you need to transfer admin rights before leaving");
      return;
    }
    
    setIsLoading(true);
    setError('');
    
    try {
      const channelRef = doc(db, "channels", channelId);
      await updateDoc(channelRef, {
        members: arrayRemove(currentUser.uid),
        membersCount: Math.max(0, (channel?.membersCount || 1) - 1)
      });
      setMembers(prev => prev.filter(member => member.id !== currentUser.uid));
      setSuccess("You have left the channel");
      setTimeout(() => onClose(), 1500);
    } catch (err) {
      console.error("Error leaving channel:", err);
      setError("Failed to leave the channel");
    } finally {
      setIsLoading(false);
    }
  };

  // Transfer admin rights
  const makeAdmin = async (userId) => {
    if (!isUserAdmin()) {
      setError("Only the current admin can transfer admin rights");
      return;
    }
    
    setIsLoading(true);
    setError('');
    
    try {
      const channelRef = doc(db, "channels", channelId);
      await updateDoc(channelRef, { admin: userId });
      setChannel(prev => ({ ...prev, admin: userId }));
      setMembers(prev => prev.map(member => ({
        ...member,
        isAdmin: member.id === userId || member.id === channel.createdBy
      })));
      setSuccess("Admin rights transferred successfully");
    } catch (err) {
      console.error("Error transferring admin rights:", err);
      setError("Failed to transfer admin rights");
    } finally {
      setIsLoading(false);
    }
  };

  const isUserMember = members.some(member => member.id === currentUser?.uid);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-medium text-gray-900">Channel Members</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X size={20} />
          </button>
        </div>
        
        {/* Channel Info */}
        <div className="px-4 py-2 bg-gray-50 border-b">
          <h3 className="font-medium">#{channel?.displayName || channel?.name}</h3>
          <p className="text-sm text-gray-500">
            {channel?.type === 'private' ? 'Private Channel' : 'Public Channel'} • {members.length} member(s)
          </p>
          {channel?.description && (
            <p className="text-sm text-gray-600 mt-1">{channel.description}</p>
          )}
        </div>
        
        {/* Tabs */}
        <div className="flex border-b">
          <button
            className={`flex-1 py-3 text-sm font-medium ${
              activeTab === 'current'
                ? 'text-blue-600 border-b-2 border-blue-600'
                : 'text-gray-500 hover:text-gray-700'
            }`}
            onClick={() => setActiveTab('current')}
          >
            <Users className="inline-block h-4 w-4 mr-1" />
            Current Members ({members.length})
          </button>
          {isUserAdmin() && (
            <button
              className={`flex-1 py-3 text-sm font-medium ${
                activeTab === 'add'
                  ? 'text-blue-600 border-b-2 border-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveTab('add')}
            >
              <UserPlus className="inline-block h-4 w-4 mr-1" />
              Add Members
            </button>
          )}
        </div>
        
        {/* Content */}
        <div className="flex-1 overflow-y-auto">
          {error && (
            <div className="m-4 p-3 bg-red-50 text-red-700 text-sm rounded-md">
              {error}
            </div>
          )}
          
          {success && (
            <div className="m-4 p-3 bg-green-50 text-green-700 text-sm rounded-md">
              {success}
            </div>
          )}
          
          {activeTab === 'current' && (
            <div className="p-4">
              {isLoading ? (
                <div className="flex justify-center py-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                </div>
              ) : members.length === 0 ? (
                <div className="text-center py-8">
                  <Users className="mx-auto h-12 w-12 text-gray-300 mb-2" />
                  <p className="text-gray-500 mb-4">No members in this channel</p>
                  <button
                    onClick={joinChannel}
                    className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Join Channel
                  </button>
                </div>
              ) : (
                <div className="space-y-2">
                  {members.map(member => (
                    <div key={member.id} className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-md">
                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium">
                          {member.firstName?.[0] || ''}{member.lastName?.[0] || ''}
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium flex items-center">
                            {member.title} {member.firstName} {member.lastName}
                            {member.isCurrentUser && (
                              <span className="ml-2 text-xs text-gray-500">(You)</span>
                            )}
                            {member.isAdmin && (
                              <Shield size={14} className="ml-2 text-blue-500" title="Channel Admin" />
                            )}
                          </p>
                          <p className="text-xs text-gray-500">{member.userRole || 'Member'}</p>
                        </div>
                      </div>
                      <div>
                        {isUserAdmin() && !member.isAdmin && !member.isCurrentUser && (
                          <button
                            onClick={() => makeAdmin(member.id)}
                            className="text-xs text-blue-600 hover:text-blue-800 mr-2"
                            title="Make admin"
                          >
                            <Shield size={14} />
                          </button>
                        )}
                        {(isUserAdmin() || member.isCurrentUser) && (
                          <button
                            onClick={() => removeMember(member.id)}
                            disabled={isLoading}
                            className="text-gray-400 hover:text-red-500"
                            title={member.isCurrentUser ? "Leave channel" : "Remove member"}
                          >
                            <UserMinus size={16} />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {members.length > 0 && (
                <div className="mt-6 pt-4 border-t flex justify-between">
                  {isUserMember ? (
                    <button
                      onClick={leaveChannel}
                      disabled={isLoading}
                      className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
                    >
                      Leave Channel
                    </button>
                  ) : (
                    <button
                      onClick={joinChannel}
                      disabled={isLoading}
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    >
                      Join Channel
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
          
          {activeTab === 'add' && (
            <div className="p-4">
              <div className="flex mb-4">
                <div className="relative flex-1">
                  <Search size={16} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search by name..."
                    className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                  />
                </div>
                <button
                  onClick={handleSearch}
                  disabled={searchTerm.length < 2 || isLoading}
                  className="ml-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-blue-300"
                >
                  Search
                </button>
              </div>
              
              {isLoading ? (
                <div className="flex justify-center py-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                </div>
              ) : searchResults.length > 0 ? (
                <div>
                  <p className="text-sm text-gray-500 mb-2">Select users to add:</p>
                  <div className="space-y-2 mb-4">
                    {searchResults.map(user => (
                      <div
                        key={user.id}
                        className={`flex items-center justify-between p-3 ${user.isSelected ? 'bg-blue-50 border border-blue-100' : 'hover:bg-gray-50'} rounded-md cursor-pointer`}
                        onClick={() => toggleUserSelection(user.id)}
                      >
                        <div className="flex items-center">
                          <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium">
                            {user.firstName?.[0] || ''}{user.lastName?.[0] || ''}
                          </div>
                          <div className="ml-3">
                            <p className="text-sm font-medium">
                              {user.title} {user.firstName} {user.lastName}
                            </p>
                            <p className="text-xs text-gray-500">{user.userRole || 'User'}</p>
                          </div>
                        </div>
                        <div className={`h-5 w-5 rounded-full flex items-center justify-center ${user.isSelected ? 'bg-blue-500 text-white' : 'border border-gray-300'}`}>
                          {user.isSelected && <Check size={12} />}
                        </div>
                      </div>
                    ))}
                  </div>
                  
                  {selectedUsers.length > 0 && (
                    <div className="mt-4 pt-4 border-t flex justify-between items-center">
                      <span className="text-sm text-gray-600">{selectedUsers.length} user(s) selected</span>
                      <button
                        onClick={addUsersToChannel}
                        disabled={isLoading}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                      >
                        Add to Channel
                      </button>
                    </div>
                  )}
                </div>
              ) : searchTerm ? (
                <div className="text-center py-8">
                  <p className="text-gray-500">No users found matching '{searchTerm}'</p>
                </div>
              ) : (
                <div className="text-center py-8">
                  <p className="text-gray-500">Search for users to add to this channel</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChannelMembers;
