// src/components/communication/AIChat.js
import React, { useState, useEffect, useRef } from 'react';
import { 
  Send, Calendar, Clock, BarChart, FileText, ChevronDown,
  PlusCircle
} from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs, 
  addDoc, 
  serverTimestamp 
} from 'firebase/firestore';

const AIChat = () => {
  const { currentUser, userProfile } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showSidebar, setShowSidebar] = useState(true);
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [timePeriod, setTimePeriod] = useState('today');
  const [dataSources, setDataSources] = useState([]);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [showDataSourcePicker, setShowDataSourcePicker] = useState(false);
  const [apiError, setApiError] = useState('');
  const messageEndRef = useRef(null);
  
  // Fetch user's conversations
  useEffect(() => {
    if (!currentUser) return;

    const fetchConversations = async () => {
      setIsLoading(true);
      try {
        const conversationsRef = collection(db, "dataInsightChats");
        const q = query(
          conversationsRef,
          where("userId", "==", currentUser.uid),
          orderBy("updatedAt", "desc")
        );

        const querySnapshot = await getDocs(q);
        const conversationsList = [];
        
        querySnapshot.forEach((doc) => {
          conversationsList.push({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate(),
            updatedAt: doc.data().updatedAt?.toDate()
          });
        });
        
        setConversations(conversationsList);
        
        // Set active conversation to the most recent one if any exist
        if (conversationsList.length > 0 && !activeConversation) {
          setActiveConversation(conversationsList[0]);
        }
      } catch (err) {
        console.error("Error fetching conversations:", err);
        setError("Failed to load conversations");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchConversations();
  }, [currentUser, activeConversation]);
  
  // Create a new conversation
  const createNewConversation = async () => {
    if (!currentUser) return;
    
    try {
      const newConversation = {
        userId: currentUser.uid,
        businessId: userProfile?.businessId || '',
        title: "New Conversation",
        messages: [],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };
      
      const docRef = await addDoc(collection(db, "dataInsightChats"), newConversation);
      
      const conversationWithId = {
        id: docRef.id,
        ...newConversation,
        createdAt: new Date(),
        updatedAt: new Date()
      };
      
      setConversations(prev => [conversationWithId, ...prev]);
      setActiveConversation(conversationWithId);
      
    } catch (err) {
      console.error("Error creating new conversation:", err);
      setError("Failed to create new conversation");
    }
  };
  
  // Scroll to bottom when messages change
  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [activeConversation?.messages]);
  
  // Handle sending message (mock implementation)
  const sendMessage = async () => {
    if (!message.trim() || !activeConversation || sending) return;
    
    try {
      setSending(true);
      setApiError('');
      
      // Format time display
      const timeContext = timePeriod.replace('_', ' ');
      const dataContext = dataSources.length > 0 
        ? dataSources.join(', ') 
        : 'all available sources';
      
      console.log(`Sending message with context: Time: ${timeContext}, Data: ${dataContext}`);
      console.log(`Message: ${message}`);
      
      // In a real app, you would:
      // 1. Add the user message to Firestore
      // 2. Call an AI service like OpenAI or a custom endpoint
      // 3. Add the AI response to Firestore
      
      // For now, we'll simulate this with a timeout
      setTimeout(() => {
        console.log("Received AI response");
        setSending(false);
        setMessage('');
      }, 1500);
      
    } catch (err) {
      console.error("Error sending message:", err);
      setApiError(`Error: ${err.message}`);
      setSending(false);
    }
  };
  
  const TimeSelector = ({ onSelect, onClose }) => {
    const timeOptions = [
      { value: 'today', label: 'Today' },
      { value: 'yesterday', label: 'Yesterday' },
      { value: 'this_week', label: 'This Week' },
      { value: 'last_week', label: 'Last Week' },
      { value: 'this_month', label: 'This Month' },
      { value: 'last_month', label: 'Last Month' },
      { value: 'this_year', label: 'This Year' },
      { value: 'last_year', label: 'Last Year' },
      { value: 'all_time', label: 'All Time' },
    ];
    
    return (
      <div className="absolute z-10 w-56 mt-1 bg-white rounded-md shadow-lg">
        <div className="py-1">
          {timeOptions.map((option) => (
            <button
              key={option.value}
              className="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onClick={() => onSelect(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    );
  };
  
  const DataSourceSelector = ({ selected, onSelect, onClose }) => {
    const dataSourceOptions = [
      { value: 'sales', label: 'Sales' },
      { value: 'inventory', label: 'Inventory' },
      { value: 'employees', label: 'Employees' },
      { value: 'customers', label: 'Customers' },
      { value: 'finances', label: 'Finances' },
      { value: 'marketing', label: 'Marketing' },
      { value: 'operations', label: 'Operations' }
    ];
    
    const toggleDataSource = (value) => {
      if (selected.includes(value)) {
        onSelect(selected.filter(item => item !== value));
      } else {
        onSelect([...selected, value]);
      }
    };
    
    return (
      <div className="absolute z-10 w-60 mt-1 bg-white rounded-md shadow-lg">
        <div className="py-1">
          {dataSourceOptions.map((option) => (
            <button
              key={option.value}
              className="w-full text-left block px-4 py-2 text-sm hover:bg-gray-100 flex items-center justify-between"
              onClick={() => toggleDataSource(option.value)}
            >
              <span className={selected.includes(option.value) ? 'font-medium text-[#190192]' : 'text-gray-700'}>
                {option.label}
              </span>
              
              {selected.includes(option.value) && (
                <svg className="h-4 w-4 text-[#190192]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              )}</button>
            ))}
          </div>
          
          <div className="py-1 px-3 border-t border-gray-100">
            <div className="flex justify-between">
              <button
                className="text-xs text-blue-600 hover:text-blue-800"
                onClick={() => onSelect(dataSourceOptions.map(option => option.value))}
              >
                Select all
              </button>
              <button
                className="text-xs text-gray-600 hover:text-gray-800"
                onClick={() => onSelect([])}
              >
                Clear all
              </button>
            </div>
          </div>
        </div>
      );
    };
   
    const ChatMessage = ({ message }) => {
      return (
        <div className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
          <div className={`max-w-[70%] rounded-lg p-4 ${
            message.role === 'user' 
              ? 'bg-[#190192] text-white rounded-tr-none' 
              : 'bg-gray-100 text-gray-800 rounded-tl-none'
          }`}>
            {message.isLoading ? (
              <div className="flex items-center">
                <div className="animate-bounce h-2 w-2 bg-gray-500 rounded-full mr-1"></div>
                <div className="animate-bounce h-2 w-2 bg-gray-500 rounded-full mr-1" style={{ animationDelay: '0.2s' }}></div>
                <div className="animate-bounce h-2 w-2 bg-gray-500 rounded-full" style={{ animationDelay: '0.4s' }}></div>
              </div>
            ) : (
              <p>{message.content}</p>
            )}
          </div>
        </div>
      );
    };
   
    return (
      <div className="flex h-full">
        {/* Sidebar with conversation history */}
        {showSidebar && (
          <div className="w-64 bg-[#190192] text-white flex flex-col h-full border-r border-gray-200">
            {/* Header */}
            <div className="p-4 border-b border-[#3d7dd6]">
              <button
                onClick={createNewConversation}
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-[#190192] bg-white hover:bg-gray-100 focus:outline-none"
              >
                <PlusCircle className="h-4 w-4 mr-1" />
                New Chat
              </button>
            </div>
            
            {/* Conversation List */}
            <div className="flex-1 overflow-y-auto py-2">
              {isLoading ? (
                <div className="flex justify-center items-center h-20">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                </div>
              ) : conversations.length === 0 ? (
                <div className="px-4 py-8 text-center text-blue-100">
                  <p className="text-sm">No conversations yet</p>
                  <p className="text-xs mt-1">Start a new chat to begin</p>
                </div>
              ) : (
                <ul className="space-y-1 px-2">
                  {conversations.map((conversation) => (
                    <li key={conversation.id}>
                      <button
                        onClick={() => setActiveConversation(conversation)}
                        className={`flex items-center w-full px-3 py-2 rounded-md ${
                          activeConversation?.id === conversation.id
                            ? 'bg-[#3d7dd6] text-white'
                            : 'text-blue-100 hover:bg-[#2d6dc6] hover:text-white'
                        }`}
                      >
                        <BarChart className="h-5 w-5 mr-2 flex-shrink-0" />
                        <div className="truncate text-left">
                          <p className="truncate font-medium">
                            {conversation.title}
                          </p>
                          <p className="text-xs truncate">
                            {conversation.updatedAt ? new Date(conversation.updatedAt).toLocaleDateString() : 'New chat'}
                          </p>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}
        
        {/* Main content area with chat interface */}
        <div className="flex-1 flex flex-col overflow-hidden">
          {activeConversation ? (
            <>
              {/* Chat Header */}
              <div className="bg-white border-b flex items-center px-4 py-3">
                <button
                  onClick={() => setShowSidebar(!showSidebar)}
                  className="p-1 rounded-md text-gray-500 hover:text-gray-700 mr-3"
                >
                  {showSidebar ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                    </svg>
                  )}
                </button>
                <h2 className="text-lg font-medium text-gray-800">
                  {activeConversation.title}
                </h2>
              </div>
              
              {/* Messages Container */}
              <div className="flex-1 overflow-y-auto p-4 bg-gray-50">
                {activeConversation.messages && activeConversation.messages.length > 0 ? (
                  activeConversation.messages.map((msg, index) => (
                    <ChatMessage key={index} message={msg} />
                  ))
                ) : (
                  <div className="flex-1 flex flex-col items-center justify-center py-12">
                    <BarChart className="h-12 w-12 text-gray-400 mb-4" />
                    <h3 className="text-lg font-medium text-gray-700 mb-2">Ask about your business data</h3>
                    <p className="text-gray-500 mb-6 text-center max-w-md">
                      Select a time period and data sources, then ask questions to gain insights
                    </p>
                    
                    <div className="w-full max-w-md px-4">
                      <div className="bg-white shadow-sm rounded-md p-4 mb-4">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">Example questions:</h4>
                        <ul className="space-y-2 text-gray-600 text-sm">
                          <li className="cursor-pointer hover:text-blue-600" onClick={() => setMessage("What were the top selling products last month?")}>
                            • What were the top selling products last month?
                          </li>
                          <li className="cursor-pointer hover:text-blue-600" onClick={() => setMessage("Show me inventory levels for items below reorder point")}>
                            • Show me inventory levels for items below reorder point
                          </li>
                          <li className="cursor-pointer hover:text-blue-600" onClick={() => setMessage("Summarize our financial performance this quarter")}>
                            • Summarize our financial performance this quarter
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                <div ref={messageEndRef} />
              </div>
              
              {/* Input Area */}
              <div className="border-t border-gray-200 bg-white p-4">
                <div className="mb-2 flex justify-between">
                  <div className="flex space-x-2">
                    {/* Time selector */}
                    <div className="relative">
                      <button 
                        className={`inline-flex items-center px-3 py-1 border ${timePeriod ? 'border-blue-500 text-blue-700 bg-blue-50' : 'border-gray-300 text-gray-700'} rounded-md text-sm`}
                        onClick={() => setShowTimePicker(!showTimePicker)}
                      >
                        <Calendar className="h-4 w-4 mr-1" />
                        {timePeriod.replace('_', ' ')}
                        <ChevronDown className="h-4 w-4 ml-1" />
                      </button>
                      
                      {showTimePicker && (
                        <TimeSelector 
                          onSelect={(period) => {
                            setTimePeriod(period);
                            setShowTimePicker(false);
                          }}
                          onClose={() => setShowTimePicker(false)}
                        />
                      )}
                    </div>
                    
                    {/* Data source selector */}
                    <div className="relative">
                      <button 
                        className={`inline-flex items-center px-3 py-1 border ${dataSources.length > 0 ? 'border-blue-500 text-blue-700 bg-blue-50' : 'border-gray-300 text-gray-700'} rounded-md text-sm`}
                        onClick={() => setShowDataSourcePicker(!showDataSourcePicker)}
                      >
                        <FileText className="h-4 w-4 mr-1" />
                        {dataSources.length > 0 ? `${dataSources.length} sources` : 'Data sources'}
                        <ChevronDown className="h-4 w-4 ml-1" />
                      </button>
                      
                      {showDataSourcePicker && (
                        <DataSourceSelector 
                          selected={dataSources}
                          onSelect={setDataSources}
                          onClose={() => setShowDataSourcePicker(false)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                
                <div className="flex rounded-md shadow-sm">
                  <textarea
                    className="flex-1 min-h-[60px] max-h-32 focus:ring-blue-500 focus:border-blue-500 block w-full rounded-md sm:text-sm border-gray-300 resize-none py-2 px-4"
                    placeholder="Ask about your business data..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        sendMessage();
                      }
                    }}
                  />
                  <button
                    type="button"
                    className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-r-md shadow-sm text-white ${
                      !message.trim() || sending 
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-[#190192] hover:bg-[#2C0DB5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    }`}
                    onClick={sendMessage}
                    disabled={!message.trim() || sending}
                  >
                    {sending ? (
                      <div className="h-5 w-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                    ) : (
                      <Send className="h-5 w-5" />
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="flex-1 flex flex-col items-center justify-center bg-gray-100 p-6">
              <BarChart className="h-16 w-16 text-gray-400 mb-4" />
              <h2 className="text-2xl font-bold text-gray-700 mb-2">AI Business Insights</h2>
              <p className="text-gray-500 mb-6 text-center max-w-md">
                Ask questions about your business data to gain insights on performance, trends, and opportunities
              </p>
              <button
                onClick={createNewConversation}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#190192] hover:bg-[#2C0DB5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <Send className="h-4 w-4 mr-2" />
                Start a New Chat
              </button>
            </div>
          )}
        </div>
      </div>
    );
   };
   
   export default AIChat;