import React, { useState, useEffect, useRef } from 'react';
import {
  MoreHorizontal,
  Download,
  Smile,
  ChevronUp,
  FileText,
  File,
  Maximize
} from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';

const MessageList = ({
  messages,
  userProfile,
  onAddReaction,
  getColorForInitial,
  messagesEndRef,
  isLoading,
  onOpenMedia
}) => {
  const [pinnedMessages, setPinnedMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showReactionPicker, setShowReactionPicker] = useState(false);
  const [showDateSeparators, setShowDateSeparators] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const messageListRef = useRef(null);

  // Common emojis for reactions
  const commonReactions = ['👍', '❤️', '😂', '🎉', '😍', '🙌'];

  // Detect mobile devices
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Generate date separators based on message timestamps
  useEffect(() => {
    if (messages.length > 0) {
      const dates = new Set();
      const separators = [];
      messages.forEach((message, index) => {
        if (message.timestamp) {
          let date;
          if (message.timestamp instanceof Date) {
            date = message.timestamp;
          } else if (message.timestamp.toDate && typeof message.timestamp.toDate === 'function') {
            date = message.timestamp.toDate();
          } else if (message.timestamp.seconds) {
            date = new Date(message.timestamp.seconds * 1000);
          } else {
            date = new Date(message.timestamp);
          }
          const dateString = date.toLocaleDateString();
          if (!dates.has(dateString)) {
            dates.add(dateString);
            separators.push({ date, index });
          }
        }
      });
      setShowDateSeparators(separators);
    }
  }, [messages]);

  // Identify pinned messages
  useEffect(() => {
    const pinned = messages.filter(message => message.pinned);
    setPinnedMessages(pinned);
  }, [messages]);

  // Toggle message options (e.g. reactions)
  const handleMessageOptions = (message) => {
    setSelectedMessage(selectedMessage?.id === message.id ? null : message);
  };

  // Format timestamp with robust error handling
  const formatMessageTime = (timestamp) => {
    if (!timestamp) return '';
    try {
      let date;
      if (timestamp instanceof Date) {
        date = timestamp;
      } else if (timestamp.toDate && typeof timestamp.toDate === 'function') {
        date = timestamp.toDate();
      } else if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        date = new Date(timestamp);
      }
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } catch (error) {
      console.error("Error formatting message time:", error, timestamp);
      return '';
    }
  };

  // Format date for separators (Today, Yesterday, or full date)
  const formatDateSeparator = (date) => {
    const now = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    
    if (date.toDateString() === now.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    }
  };

  const isMessageAtSeparator = (index) => {
    return showDateSeparators.some(separator => separator.index === index);
  };

  const getDateSeparator = (index) => {
    const separator = showDateSeparators.find(sep => sep.index === index);
    return separator ? formatDateSeparator(separator.date) : null;
  };

  // Render an attachment (images, videos, pdfs, or default file)
  const renderAttachment = (attachment, index, message) => {
    if (!attachment) return null;
    
    if (!attachment.type) {
      return (
        <div key={index} className="mt-1 bg-gray-50 border rounded-md p-3 inline-block max-w-xs">
          <div className="flex items-center">
            <div className="w-8 h-8 bg-blue-50 rounded-md flex items-center justify-center text-blue-500 mr-3">
              <File size={16} />
            </div>
            <div>
              <p className="text-sm font-medium text-gray-900">{attachment.name}</p>
              <p className="text-xs text-gray-500">
                {attachment.size ? `${Math.round(attachment.size/1024)} KB` : 'Unknown size'}
              </p>
            </div>
            <a 
              href={attachment.url} 
              download={attachment.name}
              target="_blank" 
              rel="noreferrer" 
              className="ml-3 text-blue-600 hover:text-blue-800 text-xs font-medium"
            >
              Download
            </a>
          </div>
        </div>
      );
    }
    
    if (attachment.type.startsWith('image/')) {
      return (
        <div key={index} className="mt-1 bg-gray-50 border rounded-md p-3 inline-block max-w-xs">
          <div className="space-y-2">
            <div 
              className="relative group cursor-pointer overflow-hidden rounded"
              onClick={() => onOpenMedia && onOpenMedia(attachment, message?.attachments || [], index)}
            >
              <img 
                src={attachment.url} 
                alt={attachment.name}
                className="max-w-[300px] rounded max-h-full object-contain transition-transform duration-200 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                <Maximize size={20} className="text-white drop-shadow-md" />
              </div>
            </div>
            <div className="flex justify-between items-center text-xs">
              <span className="text-gray-500 truncate max-w-[180px]" title={attachment.name}>
                {attachment.name}
              </span>
              <a 
                href={attachment.url} 
                download={attachment.name}
                target="_blank" 
                rel="noreferrer" 
                className="text-blue-600 hover:text-blue-800"
                onClick={(e) => e.stopPropagation()}
              >
                <Download size={14} />
              </a>
            </div>
          </div>
        </div>
      );
    } else if (attachment.type.startsWith('video/')) {
      return (
        <div key={index} className="mt-1 bg-gray-50 border rounded-md p-3 inline-block max-w-xs">
          <div className="space-y-2">
            <div 
              className="relative rounded overflow-hidden cursor-pointer"
              onClick={() => onOpenMedia && onOpenMedia(attachment, message?.attachments || [], index)}
            >
              <video 
                className="max-w-full rounded max-h-48 object-contain"
                controls
                preload="metadata"
              >
                <source src={attachment.url} type={attachment.type} />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="flex justify-between items-center text-xs">
              <span className="text-gray-500 truncate max-w-[180px]" title={attachment.name}>
                {attachment.name}
              </span>
              <a 
                href={attachment.url} 
                download={attachment.name}
                target="_blank" 
                rel="noreferrer" 
                className="text-blue-600 hover:text-blue-800"
                onClick={(e) => e.stopPropagation()}
              >
                <Download size={14} />
              </a>
            </div>
          </div>
        </div>
      );
    } else if (attachment.type.includes('pdf')) {
      return (
        <div key={index} className="mt-1 bg-gray-50 border rounded-md p-3 inline-block max-w-xs">
          <div className="flex items-center">
            <div className="w-8 h-8 bg-red-50 rounded-md flex items-center justify-center text-red-500 mr-3">
              <FileText size={16} />
            </div>
            <div>
              <p className="text-sm font-medium text-gray-900">{attachment.name}</p>
              <p className="text-xs text-gray-500">
                {attachment.size ? `${Math.round(attachment.size/1024)} KB` : 'Unknown size'}
              </p>
            </div>
            <a 
              href={attachment.url} 
              download={attachment.name}
              target="_blank" 
              rel="noreferrer" 
              className="ml-3 text-blue-600 hover:text-blue-800 text-xs font-medium"
            >
              Download
            </a>
          </div>
        </div>
      );
    } else {
      // Default file attachment
      return (
        <div key={index} className="mt-1 bg-gray-50 border rounded-md p-3 inline-block max-w-xs">
          <div className="flex items-center">
            <div className="w-8 h-8 bg-blue-50 rounded-md flex items-center justify-center text-blue-500 mr-3">
              <File size={16} />
            </div>
            <div>
              <p className="text-sm font-medium text-gray-900">{attachment.name}</p>
              <p className="text-xs text-gray-500">
                {attachment.size ? `${Math.round(attachment.size/1024)} KB` : 'Unknown size'}
              </p>
            </div>
            <a 
              href={attachment.url} 
              download={attachment.name}
              target="_blank" 
              rel="noreferrer" 
              className="ml-3 text-blue-600 hover:text-blue-800 text-xs font-medium"
            >
              Download
            </a>
          </div>
        </div>
      );
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!messages || messages.length === 0) {
    return (
      <div className="flex items-center justify-center h-full p-4">
        <div className="text-center text-gray-500">
          <p className="text-lg mb-2">No messages yet</p>
          <p className="text-sm">Start the conversation by sending a message!</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-2 space-y-4" ref={messageListRef}>
      {/* Render Pinned Message if available */}
      {pinnedMessages.length > 0 && (
        <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-3 mb-4 sticky top-0 z-10">
          <div className="flex items-start">
            <div className="flex-shrink-0 mt-0.5">
              <div className={`w-8 h-8 rounded-full bg-${getColorForInitial(pinnedMessages[0].senderInitials?.[0] || 'A')}-100 flex items-center justify-center text-${getColorForInitial(pinnedMessages[0].senderInitials?.[0] || 'A')}-600 font-medium`}>
                {pinnedMessages[0].senderInitials}
              </div>
            </div>
            <div className="ml-2 flex-1 min-w-0">
              <div className="flex items-center justify-between flex-wrap">
                <div className="flex items-center mr-2 mb-1">
                  <span className="font-medium text-gray-900 truncate">
                    {pinnedMessages[0].senderName}
                  </span>
                  {pinnedMessages[0].senderRole && (
                    <span className="ml-2 text-xs text-gray-500 font-medium bg-blue-100 text-blue-800 px-1.5 py-0.5 rounded">
                      {pinnedMessages[0].senderRole}
                    </span>
                  )}
                  <span className="ml-2 text-xs text-gray-500">
                    {formatMessageTime(pinnedMessages[0].timestamp)}
                  </span>
                </div>
                <div className="flex items-center text-gray-400">
                  <span className="text-xs text-yellow-600 font-medium flex items-center mr-2">
                    <ChevronUp size={12} className="mr-1" />
                    Pinned
                  </span>
                  <button 
                    className="hover:text-gray-600"
                    onClick={() => handleMessageOptions(pinnedMessages[0])}
                  >
                    <MoreHorizontal size={16} />
                  </button>
                </div>
              </div>
              <div className="mt-1 text-sm text-gray-800 break-words">
                {pinnedMessages[0].content}
              </div>
              {pinnedMessages[0].attachments && pinnedMessages[0].attachments.length > 0 && (
                <div className="mt-2 space-y-2">
                  {pinnedMessages[0].attachments.map((attachment, idx) => 
                    renderAttachment(attachment, idx, pinnedMessages[0])
                  )}
                </div>
              )}
              {pinnedMessages[0].reactions && Object.keys(pinnedMessages[0].reactions).length > 0 && (
                <div className="mt-2 flex items-center flex-wrap gap-2">
                  {Object.entries(pinnedMessages[0].reactions).map(([emoji, users]) => (
                    <button 
                      key={emoji}
                      className="inline-flex items-center px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded-full text-xs text-gray-700"
                      onClick={() => onAddReaction && onAddReaction(pinnedMessages[0].id, emoji)}
                    >
                      {emoji} {Array.isArray(users) ? users.length : 0}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Render Regular Messages with Date Separators */}
      {messages.map((message, index) => (
        <React.Fragment key={message.id || index}>
          {isMessageAtSeparator(index) && (
            <div className="flex items-center my-6">
              <div className="flex-grow h-px bg-gray-200"></div>
              <span className="flex-shrink-0 text-xs text-gray-500 px-3">
                {getDateSeparator(index)}
              </span>
              <div className="flex-grow h-px bg-gray-200"></div>
            </div>
          )}
          <div className="flex items-start group relative">
            <div className="flex-shrink-0 mt-0.5">
              <div className={`w-8 h-8 rounded-full bg-${getColorForInitial(message.senderInitials?.[0] || 'A')}-100 flex items-center justify-center text-${getColorForInitial(message.senderInitials?.[0] || 'A')}-600 font-medium`}>
                {message.senderInitials}
              </div>
            </div>
            <div className="ml-2 flex-1 min-w-0">
              <div className="flex items-center justify-between flex-wrap">
                <div className="flex items-center flex-wrap mr-2 mb-1">
                  <span className="font-medium text-sm text-gray-900 truncate">
                    {message.senderName}
                  </span>
                  {message.senderRole && (
                    <span className="ml-2 text-xs text-gray-500 font-medium bg-gray-100 px-1.5 py-0.5 rounded">
                      {message.senderRole}
                    </span>
                  )}
                  <span className="ml-2 text-xs text-gray-500">
                    {formatMessageTime(message.timestamp)}
                  </span>
                </div>
                <div className="flex items-center text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity">
                  <button 
                    className="hover:text-gray-600 ml-1"
                    onClick={() => handleMessageOptions(message)}
                  >
                    <MoreHorizontal size={16} />
                  </button>
                </div>
              </div>
              <div className="mt-1 text-md text-gray-800 break-words">
                {message.content}
              </div>
              {message.attachments && message.attachments.length > 0 && (
                <div className="mt-2 space-y-2">
                  {message.attachments.map((attachment, attIndex) => 
                    renderAttachment(attachment, attIndex, message)
                  )}
                </div>
              )}
              {message.reactions && Object.keys(message.reactions).length > 0 && (
                <div className="mt-2 flex items-center flex-wrap gap-2">
                  {Object.entries(message.reactions).map(([emoji, users]) => (
                    <button 
                      key={emoji}
                      className="inline-flex items-center px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded-full text-xs text-gray-700"
                      onClick={() => onAddReaction && onAddReaction(message.id, emoji)}
                    >
                      {emoji} {Array.isArray(users) ? users.length : 0}
                    </button>
                  ))}
                  <div className="relative">
                    <button 
                      className="inline-flex items-center px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded-full text-xs text-gray-700"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedMessage(message);
                        setShowReactionPicker(!showReactionPicker);
                      }}
                    >
                      <Smile size={14} className="mr-1" />
                      React
                    </button>
                    {showReactionPicker && selectedMessage?.id === message.id && (
                      <div className="absolute top-0 mt-8 left-0 bg-white border border-gray-200 shadow-md rounded-md p-2 z-10">
                        <div className="flex space-x-1">
                          {commonReactions.map((emoji) => (
                            <button
                              key={emoji}
                              className="hover:bg-gray-100 p-1 rounded"
                              onClick={() => {
                                onAddReaction && onAddReaction(message.id, emoji);
                                setShowReactionPicker(false);
                              }}
                            >
                              {emoji}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {selectedMessage?.id === message.id && (
                <div className="absolute mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-md z-10">
                  <div className="py-1">
                    <button
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => {
                        setShowReactionPicker(true);
                        setSelectedMessage(message);
                      }}
                    >
                      Add Reaction
                    </button>
                    {/* Additional options can be added here */}
                  </div>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      ))}
      <div ref={messagesEndRef} className="h-1" />
    </div>
  );
};

export default MessageList;