// src/components/communication/ChannelList.js
import React, { useState, useEffect, useRef } from 'react';
import { Plus, Lock, AlertCircle, MoreHorizontal, Edit, Trash2, Settings, ChevronDown } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { collection, addDoc, doc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';

const ChannelList = ({ channels, activeChannel, onChannelSelect, isDirectMessage }) => {
  const { currentUser, userProfile } = useAuth();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [channelToEdit, setChannelToEdit] = useState(null);
  const [newChannelName, setNewChannelName] = useState('');
  const [newChannelType, setNewChannelType] = useState('public');
  const [newChannelDescription, setNewChannelDescription] = useState('');
  const [error, setError] = useState('');
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);
  
  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    }
    
    if (activeDropdown !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeDropdown]);

  // Create new channel
  const handleCreateChannel = async (e) => {
    e.preventDefault();
    
    // Clear previous errors
    setError('');
    
    // Validate authentication
    if (!currentUser) {
      setError('You must be logged in to create a channel');
      return;
    }
    
    // Validate channel name
    if (!newChannelName.trim()) {
      setError('Channel name is required');
      return;
    }
    
    try {
      // Create a new channel document
      const channelData = {
        name: newChannelName.trim().toLowerCase().replace(/\s+/g, '-'),
        displayName: newChannelName.trim(),
        description: newChannelDescription.trim(),
        type: newChannelType,
        createdBy: currentUser.uid,
        admin: currentUser.uid, // Set creator as admin
        businessId: userProfile?.businessId || '',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        membersCount: 1,
        members: [currentUser.uid]
      };
      
      const docRef = await addDoc(collection(db, "channels"), channelData);
      
      // Reset form
      setNewChannelName('');
      setNewChannelDescription('');
      setNewChannelType('public');
      setShowCreateForm(false);
      
      // Automatically select the new channel
      onChannelSelect(docRef.id);
      
    } catch (error) {
      console.error("Error creating channel:", error);
      setError(`Failed to create channel: ${error.message}`);
    }
  };

  // Edit existing channel
  const handleEditChannel = async (e) => {
    e.preventDefault();
    
    if (!channelToEdit) return;
    
    if (!newChannelName.trim()) {
      setError('Channel name is required');
      return;
    }
    
    try {
      // Update channel data
      const channelRef = doc(db, "channels", channelToEdit.id);
      
      await updateDoc(channelRef, {
        displayName: newChannelName.trim(),
        description: newChannelDescription.trim(),
        type: newChannelType,
        updatedAt: serverTimestamp()
      });
      
      // Reset form
      setNewChannelName('');
      setNewChannelDescription('');
      setNewChannelType('public');
      setShowEditForm(false);
      setChannelToEdit(null);
      
    } catch (error) {
      console.error("Error updating channel:", error);
      setError(`Failed to update channel: ${error.message}`);
    }
  };

  // Delete channel
  const handleDeleteChannel = async (channelId) => {
    if (!window.confirm("Are you sure you want to delete this channel? This action cannot be undone.")) {
      return;
    }
    
    try {
      await deleteDoc(doc(db, "channels", channelId));
      
      // If deleted the active channel, select another one
      if (activeChannel === channelId) {
        const nextChannel = channels.find(c => c.id !== channelId);
        if (nextChannel) {
          onChannelSelect(nextChannel.id);
        } else {
          // No channels left
          onChannelSelect(null);
        }
      }
      
      setActiveDropdown(null);
    } catch (error) {
      console.error("Error deleting channel:", error);
      setError(`Failed to delete channel: ${error.message}`);
    }
  };
  
  // Handle member removal
  const handleManageMembers = (channelId) => {
    // Open the channel members modal
    if (typeof window.channelMembersModal?.openModal === 'function') {
      window.channelMembersModal.openModal(channelId);
    }
    setActiveDropdown(null);
  };

  // Helper to setup edit form
  const editChannel = (channel) => {
    setChannelToEdit(channel);
    setNewChannelName(channel.displayName || channel.name);
    setNewChannelDescription(channel.description || '');
    setNewChannelType(channel.type || 'public');
    setShowEditForm(true);
    setActiveDropdown(null);
  };

  // Check if current user is an admin of the channel
  const isChannelAdmin = (channel) => {
    return channel.admin === currentUser?.uid || channel.createdBy === currentUser?.uid;
  };

  // Check if current user is a member of the channel
  const isChannelMember = (channel) => {
    return channel.members?.includes(currentUser?.uid);
  };

  // Toggle options dropdown
  const toggleDropdown = (channelId, e) => {
    e.stopPropagation();
    setActiveDropdown(activeDropdown === channelId ? null : channelId);
  };
  
  return (
    <div className="px-4 py-3 flex-none">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-xs font-semibold text-[#f7f7f7] uppercase tracking-wider">Channels</h3>
        <button 
          className="text-[#f7f7f7] hover:text-white"
          onClick={() => setShowCreateForm(!showCreateForm)}
        >
          <Plus size={16} />
        </button>
      </div>
      
      {/* Error message */}
      {error && (
        <div className="mb-3 p-2 bg-red-400 bg-opacity-20 rounded-md">
          <p className="text-xs text-red-100 flex items-center">
            <AlertCircle size={12} className="mr-1" />
            {error}
          </p>
        </div>
      )}
      
      {/* Create Channel Form */}
      {showCreateForm && (
        <div className="mb-3 bg-[#ffffff22] p-2 rounded-md">
          <form onSubmit={handleCreateChannel}>
            <input
              type="text"
              placeholder="Channel name"
              className="w-full px-2 py-1 text-sm border rounded mb-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white text-gray-800"
              value={newChannelName}
              onChange={(e) => setNewChannelName(e.target.value)}
            />
            <textarea
              placeholder="Description (optional)"
              className="w-full px-2 py-1 text-sm border rounded mb-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white text-gray-800"
              value={newChannelDescription}
              onChange={(e) => setNewChannelDescription(e.target.value)}
              rows={2}
            />
            <div className="flex items-center space-x-2 mb-2">
              <label className="flex items-center text-xs text-[#f7f7f7]">
                <input
                  type="radio"
                  name="channelType"
                  value="public"
                  checked={newChannelType === 'public'}
                  onChange={() => setNewChannelType('public')}
                  className="mr-1"
                />
                Public
              </label>
              <label className="flex items-center text-xs text-[#f7f7f7]">
                <input
                  type="radio"
                  name="channelType"
                  value="private"
                  checked={newChannelType === 'private'}
                  onChange={() => setNewChannelType('private')}
                  className="mr-1"
                />
                Private
              </label>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="px-2 py-1 text-xs text-[#f7f7f7] hover:text-white mr-2"
                onClick={() => setShowCreateForm(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-2 py-1 text-xs bg-white text-[#190192] rounded hover:bg-gray-100"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Edit Channel Form */}
      {showEditForm && channelToEdit && (
        <div className="mb-3 bg-[#ffffff22] p-2 rounded-md">
          <form onSubmit={handleEditChannel}>
            <input
              type="text"
              placeholder="Channel name"
              className="w-full px-2 py-1 text-sm border rounded mb-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white text-gray-800"
              value={newChannelName}
              onChange={(e) => setNewChannelName(e.target.value)}
            />
            <textarea
              placeholder="Description (optional)"
              className="w-full px-2 py-1 text-sm border rounded mb-2 focus:outline-none focus:ring-1 focus:ring-blue-500 bg-white text-gray-800"
              value={newChannelDescription}
              onChange={(e) => setNewChannelDescription(e.target.value)}
              rows={2}
            />
            <div className="flex items-center space-x-2 mb-2">
              <label className="flex items-center text-xs text-[#f7f7f7]">
                <input
                  type="radio"
                  name="editChannelType"
                  value="public"
                  checked={newChannelType === 'public'}
                  onChange={() => setNewChannelType('public')}
                  className="mr-1"
                />
                Public
              </label>
              <label className="flex items-center text-xs text-[#f7f7f7]">
                <input
                  type="radio"
                  name="editChannelType"
                  value="private"
                  checked={newChannelType === 'private'}
                  onChange={() => setNewChannelType('private')}
                  className="mr-1"
                />
                Private
              </label>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                className="px-2 py-1 text-xs text-[#f7f7f7] hover:text-white mr-2"
                onClick={() => {
                  setShowEditForm(false); 
                  setChannelToEdit(null);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-2 py-1 text-xs bg-white text-[#190192] rounded hover:bg-gray-100"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      )}
      
      {/* Channels List */}
      <div className="space-y-1">
        {channels.map((channel) => (
          <div
            key={channel.id}
            className={`flex items-center justify-between w-full rounded-md text-sm ${
              activeChannel === channel.id && !isDirectMessage
                ? 'bg-[#ffffff33] text-white'
                : 'hover:bg-[#ffffff22] text-[#f7f7f7]'
            }`}
          >
            <button
              className="flex items-center py-1.5 px-2 w-full text-left"
              onClick={() => onChannelSelect(channel.id)}
            >
              <span className="mr-2">#</span>
              <span className="truncate">{channel.displayName || channel.name}</span>
              {channel.type === 'private' && (
                <Lock size={12} className="ml-1 text-gray-400 flex-shrink-0" />
              )}
            </button>
            
            <div className="relative flex items-center">
              {channel.unread > 0 && (
                <span className="bg-white text-[#190192] text-xs font-medium px-2 py-0.5 rounded-full mr-1">
                  {channel.unread}
                </span>
              )}
              
              <button
                className="px-1.5 py-1.5 text-[#f7f7f7] hover:text-white rounded"
                onClick={(e) => toggleDropdown(channel.id, e)}
              >
                <MoreHorizontal size={14} />
              </button>
              
              {activeDropdown === channel.id && (
                <div 
                  ref={dropdownRef}
                  className="absolute right-0 top-full mt-1 w-40 bg-white rounded-md shadow-lg z-10">
                  {isChannelAdmin(channel) ? (
                    <div className="py-1">
                      <button
                        className="w-full text-left px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          editChannel(channel);
                        }}
                      >
                        <Edit size={14} className="mr-2" />
                        Edit Channel
                      </button>
                      <button
                        className="w-full text-left px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleManageMembers(channel.id);
                        }}
                      >
                        <Settings size={14} className="mr-2" />
                        Manage Members
                      </button>
                      <button
                        className="w-full text-left px-3 py-1.5 text-sm text-red-600 hover:bg-gray-100 flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteChannel(channel.id);
                        }}
                      >
                        <Trash2 size={14} className="mr-2" />
                        Delete Channel
                      </button>
                    </div>
                  ) : isChannelMember(channel) ? (
                    <div className="py-1">
                      <button
                        className="w-full text-left px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleManageMembers(channel.id);
                        }}
                      >
                        <Settings size={14} className="mr-2" />
                        View Members
                      </button>
                      <button
                        className="w-full text-left px-3 py-1.5 text-sm text-red-600 hover:bg-gray-100 flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          // Leave channel - This would be implemented in ChannelMembers component
                          handleManageMembers(channel.id);
                        }}
                      >
                        <Trash2 size={14} className="mr-2" />
                        Leave Channel
                      </button>
                    </div>
                  ) : (
                    <div className="py-1">
                      <button
                        className="w-full text-left px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleManageMembers(channel.id);
                        }}
                      >
                        <Settings size={14} className="mr-2" />
                        Join Channel
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
        
        {channels.length === 0 && (
          <div className="text-center py-4">
            <p className="text-sm text-[#f7f7f7] opacity-70">No channels available</p>
            <button
              onClick={() => setShowCreateForm(true)}
              className="mt-2 px-3 py-1 text-xs bg-[#ffffff22] text-[#f7f7f7] rounded hover:bg-[#ffffff33]"
            >
              <Plus size={12} className="inline mr-1" />
              Create your first channel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChannelList;