// src/components/communication/MessageInput.jsx
import React, { useRef, useState } from 'react';
import { 
  Plus, 
  Send, 
  Image, 
  File, 
  Smile, 
  Paperclip,
  Video,
  Music,
  X
} from 'lucide-react';

const MessageInput = ({ 
  newMessage, 
  setNewMessage, 
  handleSendMessage, 
  handleFileUpload, 
  attachments, 
  handleRemoveAttachment, 
  error, 
  setError, 
  isLoading, 
  isDragging,
  showEmojiPicker,
  setShowEmojiPicker,
  handleEmojiSelect
}) => {
  const [showInputOptions, setShowInputOptions] = useState(false);
  const messageInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  
  // Handle key press (Enter to send)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };
  
  // Handle file input change
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFileUpload(file);
      
      // Reset the input to allow selecting the same file again
      e.target.value = '';
      setShowInputOptions(false);
    }
  };

  return (
    <div className="relative">
      {/* Error display for file uploads */}
      {error && (
        <div className="px-3 py-2 bg-red-50 text-red-800 text-sm flex items-start border-b border-red-200">
          <div className="flex-1">
            <p className="font-medium">Error</p>
            <p className="text-xs">{error}</p>
          </div>
          <button 
            onClick={() => setError('')}
            className="ml-auto text-red-600 hover:text-red-800"
          >
            <X size={16} />
          </button>
        </div>
      )}

      {/* Input Options (vertical menu) */}
      {showInputOptions && (
        <div className="absolute bottom-full mb-2 left-0 bg-white rounded-lg shadow-lg border overflow-hidden z-10">
          <div className="p-1 space-y-1">
            <button 
              className="w-full flex items-center p-2 text-gray-700 hover:bg-gray-100 rounded"
              onClick={() => fileInputRef.current?.click()}
              disabled={isLoading}
              title="Attach file"
            >
              <Paperclip size={18} className="mr-2" />
              <span className="text-sm">File</span>
            </button>
            
            <button 
              className="w-full flex items-center p-2 text-gray-700 hover:bg-gray-100 rounded"
              onClick={() => imageInputRef.current?.click()}
              disabled={isLoading}
              title="Attach image"
            >
              <Image size={18} className="mr-2" />
              <span className="text-sm">Image</span>
            </button>
            
            <button 
              className="w-full flex items-center p-2 text-gray-700 hover:bg-gray-100 rounded"
              onClick={() => videoInputRef.current?.click()}
              disabled={isLoading}
              title="Attach video"
            >
              <Video size={18} className="mr-2" />
              <span className="text-sm">Video</span>
            </button>
            
            <button 
              className="w-full flex items-center p-2 text-gray-700 hover:bg-gray-100 rounded"
              onClick={() => {
                setShowEmojiPicker(!showEmojiPicker);
                setShowInputOptions(false);
              }}
              title="Add emoji"
            >
              <Smile size={18} className="mr-2" />
              <span className="text-sm">Emoji</span>
            </button>
          </div>
          
          {/* Hidden input fields */}
          <input 
            type="file" 
            ref={fileInputRef}
            className="hidden" 
            onChange={handleFileInputChange}
            disabled={isLoading}
          />
          <input 
            type="file" 
            ref={imageInputRef}
            className="hidden" 
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) handleFileUpload(file);
              e.target.value = '';
            }}
            disabled={isLoading}
          />
          <input 
            type="file" 
            ref={videoInputRef}
            className="hidden" 
            accept="video/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) handleFileUpload(file);
              e.target.value = '';
            }}
            disabled={isLoading}
          />
        </div>
      )}
      
      {/* Emoji Picker would be implemented here */}
      
      {/* Input Area */}
      <div className="flex items-start p-2">
        <button 
          className={`mr-2 p-2 text-gray-500 hover:text-blue-600 hover:bg-blue-50 rounded-full ${showInputOptions ? 'text-blue-600 bg-blue-50' : ''}`}
          onClick={() => setShowInputOptions(!showInputOptions)}
          disabled={isLoading}
        >
          <Plus size={22} />
        </button>
        
        <div className="flex-1 relative">
          <textarea
            ref={messageInputRef}
            className="w-full bg-gray-50 border rounded-lg px-3 py-2 overflow-y-auto text-md focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none min-h-[60px] max-h-[150px]"
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            rows={1}
            style={{
              height: newMessage.split('\n').length > 2 ? 'auto' : '40px'
            }}
          ></textarea>
        </div>
        
        <button 
          className="ml-1 bg-[#1d67cd] text-white p-2 rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
          onClick={handleSendMessage}
          disabled={(!newMessage.trim() && attachments.length === 0) || isLoading}
        >
          <Send size={18} />
        </button>
      </div>
    </div>
  );
};

export default MessageInput;