// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  auth, 
  db 
} from '../firebase/config';
import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged
} from 'firebase/auth';
import { 
  doc, 
  getDoc, 
  setDoc, 
  serverTimestamp 
} from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isDemoMode, setIsDemoMode] = useState(false);

  // Register with email/password
  async function register(email, password, name) {
    try {
      setError('');
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create user profile in Firestore
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email,
        displayName: name,
        createdAt: serverTimestamp(),
        lastLogin: serverTimestamp()
      });

      return userCredential.user;
    } catch (err) {
      setError(handleFirebaseError(err));
      throw err;
    }
  }

  const enableDemoMode = () => {
    const demoUser = {
      uid: 'demo-user-id',
      email: 'demo@bizcentral.example',
      displayName: 'Demo User',
      isDemoUser: true
    };
    
    setCurrentUser(demoUser);
    setUserProfile({
      id: 'demo-profile-id',
      userId: demoUser.uid,
      firstName: 'Guest',
      lastName: '@BizCentral',
      businessName: 'Demo Business',
      role: 'Owner',
      createdAt: new Date()
    });
    setIsDemoMode(true);
    
    // Store in localStorage to persist through refreshes
    localStorage.setItem('bizcentral_demo_mode', 'true');
  };
  
  // Disable demo mode
  const disableDemoMode = () => {
    setIsDemoMode(false);
    setCurrentUser(null);
    setUserProfile(null);
    localStorage.removeItem('bizcentral_demo_mode');
  };

  // Login with email/password
  async function login(email, password) {
    try {
      setError('');
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // Update last login
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        lastLogin: serverTimestamp()
      }, { merge: true });

      return userCredential.user;
    } catch (err) {
      setError(handleFirebaseError(err));
      throw err;
    }
  }

  // Google sign-in
  async function googleSignIn() {
    try {
      setError('');
      const provider = new GoogleAuthProvider();
      
      // This is likely where the error is happening
      // Make sure to use signInWithPopup correctly
      const result = await signInWithPopup(auth, provider);
      const userCredential = result;
      
      // Check if user profile exists, if not create it
      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      
      if (!userDoc.exists()) {
        await setDoc(doc(db, 'users', userCredential.user.uid), {
          email: userCredential.user.email,
          displayName: userCredential.user.displayName,
          photoURL: userCredential.user.photoURL,
          createdAt: serverTimestamp(),
          lastLogin: serverTimestamp()
        });
      } else {
        // Update last login
        await setDoc(doc(db, 'users', userCredential.user.uid), {
          lastLogin: serverTimestamp()
        }, { merge: true });
      }
  
      return userCredential.user;
    } catch (err) {
      console.error("Google sign-in error:", err);
      setError(handleFirebaseError(err));
      throw err;
    }
  }

  // Logout
  async function logout() {
    try {
      await signOut(auth);
    } catch (err) {
      setError(handleFirebaseError(err));
      throw err;
    }
  }

  // Reset password
  async function resetPassword(email) {
    try {
      setError('');
      await sendPasswordResetEmail(auth, email);
    } catch (err) {
      setError(handleFirebaseError(err));
      throw err;
    }
  }

  // Fetch user profile
  async function fetchUserProfile(uid) {
    try {
      const userProfileDoc = await getDoc(doc(db, 'userProfiles', uid));
      if (userProfileDoc.exists()) {
        return { id: uid, ...userProfileDoc.data() };
      }
      return null;
    } catch (err) {
      console.error('Error fetching user profile:', err);
      return null;
    }
  }

  async function refreshUserProfile() {
    if (currentUser) {
      try {
        const profile = await fetchUserProfile(currentUser.uid);
        setUserProfile(profile);
        return profile;
      } catch (error) {
        console.error("Error refreshing user profile:", error);
        return null;
      }
    }
    return null;
  }
  // Helper function to handle Firebase errors
  function handleFirebaseError(error) {
    switch (error.code) {
      case 'auth/user-not-found':
        return 'No account found with this email address.';
      case 'auth/wrong-password':
        return 'Incorrect password. Please try again.';
      case 'auth/email-already-in-use':
        return 'This email address is already in use.';
      case 'auth/weak-password':
        return 'Password should be at least 6 characters.';
      case 'auth/invalid-email':
        return 'Invalid email address format.';
      case 'auth/account-exists-with-different-credential':
        return 'An account already exists with the same email address but different sign-in credentials.';
      case 'auth/popup-closed-by-user':
        return 'Sign-in popup was closed before completing the sign in.';
      default:
        return error.message || 'An error occurred during authentication.';
    }
  }

  // Listen for auth state changes
  useEffect(() => {

    const isDemoActive = localStorage.getItem('bizcentral_demo_mode') === 'true';
    
    if (isDemoActive) {
      enableDemoMode();
      setLoading(false);
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      
      if (user) {
        const profile = await fetchUserProfile(user.uid);
        setUserProfile(profile);
      } else {
        setUserProfile(null);
      }
      
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userProfile,
    refreshUserProfile,
    loading,
    error,
    register,
    login,
    googleSignIn,
    logout,
    resetPassword, 
    isDemoMode,
    enableDemoMode,
    disableDemoMode
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}